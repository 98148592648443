.family-tree {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

iframe {
    width: 100%; /* Ensure the iframe takes up full width of the container */
    height: 90%; /* Ensure the iframe takes up full height of the container */
  
  }

  .family-tree-header {
    margin: 20px 0;
    color: #4b8fc0; 
  }