@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
  text-decoration: turquoise;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.marker {
  transform: translate(-50%, -100%);
}

.error {
  color: #f05305;
}

.marker.red path {
  fill: #f05305;
}

.marker {
  transform: scale(0.5) translate(-100%, -100%);
}

.popup {
  width: 50vw;
  padding: 1vw;
  max-width: 100%;
}

.popup img {
  height: 40vw;
  width: 40vw;
  text-align: center;
  padding: 1vw;
  float: left;
}

.popup p {
  display: inline;
}

.entry-form label,
.entry-form input,
.entry-form textarea,
.entry-form button {
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  margin: 0.5rem 0;
  display: block;
  width: 50%;
}

* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}
.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
/* START Narrative Modal Styling */
.narrative-modal-area {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 75%;
  height: auto;
  padding: 1.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  max-width: 75em;
  max-height: calc(100% - 1em);
  -webkit-overflow-scrolling: touch;
}

.narrative-card {
  display: flex;
}

.narrative-img-container {
  margin: 10px;
  min-width: 40vw;
  max-height: 85vh; 
}

.narrative-img { 
  width: 100%;
  height: 100%; 
  object-fit: contain;
}

.narrative-video {
  margin: auto 10px;
  width: 40vw;
  height: 85vh;
}

.narrative-title {
  font-weight: bold;
  font-size: x-large;
}

.narrative-body {
  color: black;
  max-height: 45vw;
  overflow-y: auto;
}

.narrative-date {
  color: grey;
}

.narrative-category {
  color: grey;
}

/* @media screen and (max-width: 1000px) and (min-width: 480px) {
  .narrative-modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 75em;
    max-height: calc(100% - 1em);
  }
} */

/*Mobile*/
@media screen and (max-width: 480px) {
  .narrative-modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    width: 95vw;
    height: 175vw;
    padding: 10px;
    background-color: #ffffff;
  }

  .narrative-card {
    display: flex;
    flex-direction: column;
  }

  .narrative-img-container {
  margin: 10px;
  min-width: 40vw;
  max-height: 40vh; 
}

.narrative-img { 
  width: 100%;
  height: 100%; 
  object-fit: contain;
  max-height: 40vh; 
}


  .narrative-body {
    color: black;
    /* width: 100%;
    height: 100%;
    overflow: auto; */
    /* min-height: 35vh; */
    max-height: 35vh;
    overflow-y: auto;
  }

  .narrative-video {
  min-width: 80vw;  
  max-height: 40vh;
}
}

/* END Narrative Modal Styling */

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 50px;
  height: 50px;
}

.cluster-marker {
  color: #fff;
  background: #ffffff;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-count {
  color: #fff;
  /* background: #0195af; */
}

#scroll {
  width: 24vw;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding: 20px;
}

#narrative {
  font-size: small;
}
