@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  -webkit-text-decoration: turquoise;
          text-decoration: turquoise;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.marker {
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}

.error {
  color: #f05305;
}

.marker.red path {
  fill: #f05305;
}

.marker {
  -webkit-transform: scale(0.5) translate(-100%, -100%);
          transform: scale(0.5) translate(-100%, -100%);
}

.popup {
  width: 50vw;
  padding: 1vw;
  max-width: 100%;
}

.popup img {
  height: 40vw;
  width: 40vw;
  text-align: center;
  padding: 1vw;
  float: left;
}

.popup p {
  display: inline;
}

.entry-form label,
.entry-form input,
.entry-form textarea,
.entry-form button {
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  margin: 0.5rem 0;
  display: block;
  width: 50%;
}

* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}
.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
/* START Narrative Modal Styling */
.narrative-modal-area {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 75%;
  height: auto;
  padding: 1.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 75em;
  max-height: calc(100% - 1em);
  -webkit-overflow-scrolling: touch;
}

.narrative-card {
  display: flex;
}

.narrative-img-container {
  margin: 10px;
  min-width: 40vw;
  max-height: 85vh; 
}

.narrative-img { 
  width: 100%;
  height: 100%; 
  object-fit: contain;
}

.narrative-video {
  margin: auto 10px;
  width: 40vw;
  height: 85vh;
}

.narrative-title {
  font-weight: bold;
  font-size: x-large;
}

.narrative-body {
  color: black;
  max-height: 45vw;
  overflow-y: auto;
}

.narrative-date {
  color: grey;
}

.narrative-category {
  color: grey;
}

/* @media screen and (max-width: 1000px) and (min-width: 480px) {
  .narrative-modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 75em;
    max-height: calc(100% - 1em);
  }
} */

/*Mobile*/
@media screen and (max-width: 480px) {
  .narrative-modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 95vw;
    height: 175vw;
    padding: 10px;
    background-color: #ffffff;
  }

  .narrative-card {
    display: flex;
    flex-direction: column;
  }

  .narrative-img-container {
  margin: 10px;
  min-width: 40vw;
  max-height: 40vh; 
}

.narrative-img { 
  width: 100%;
  height: 100%; 
  object-fit: contain;
  max-height: 40vh; 
}


  .narrative-body {
    color: black;
    /* width: 100%;
    height: 100%;
    overflow: auto; */
    /* min-height: 35vh; */
    max-height: 35vh;
    overflow-y: auto;
  }

  .narrative-video {
  min-width: 80vw;  
  max-height: 40vh;
}
}

/* END Narrative Modal Styling */

@media screen and (min-width: 500px) {
  /* Center the Modal! */
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 50px;
  height: 50px;
}

.cluster-marker {
  color: #fff;
  background: #ffffff;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster-count {
  color: #fff;
  /* background: #0195af; */
}

#scroll {
  width: 24vw;
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  padding: 20px;
}

#narrative {
  font-size: small;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #fdffa3;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    position: relative;
    left: 200px;
    padding: 15px 32px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: #fdffa3;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

.family-tree {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

iframe {
    width: 100%; /* Ensure the iframe takes up full width of the container */
    height: 90%; /* Ensure the iframe takes up full height of the container */
  
  }

  .family-tree-header {
    margin: 20px 0;
    color: #4b8fc0; 
  }
